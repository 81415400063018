// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Price = require("../Price.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../ErrorHandling/UnselectedUnionMemberError.bs.js");
var PropertyPrice_price_graphql = require("../../../__generated__/PropertyPrice_price_graphql.bs.js");
var PropertyPrice_property_graphql = require("../../../__generated__/PropertyPrice_property_graphql.bs.js");
var PropertyPrice_propertyPrice_graphql = require("../../../__generated__/PropertyPrice_propertyPrice_graphql.bs.js");

var convertFragment = PropertyPrice_property_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, PropertyPrice_property_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_property_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(PropertyPrice_property_graphql.node, convertFragment, fRef);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = PropertyPrice_price_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, PropertyPrice_price_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_price_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_price_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(PropertyPrice_price_graphql.node, convertFragment$1, fRef);
}

var PropertyPriceFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertFragment$2 = PropertyPrice_propertyPrice_graphql.Internal.convertFragment;

function waitForFragmentData$2(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, PropertyPrice_propertyPrice_graphql.node, convertFragment$2, fRef);
}

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyPrice_propertyPrice_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyPrice_propertyPrice_graphql.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(PropertyPrice_propertyPrice_graphql.node, convertFragment$2, fRef);
}

var PropertyPricePriceFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  waitForFragmentData: waitForFragmentData$2,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function PropertyPrice$PriceInternal$PriceDisplay(props) {
  var __children = props.children;
  var className = props.className;
  var size = props.size;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var propertyPrice = use$2(props.propertyPrice);
  switch (propertyPrice.__typename) {
    case "ExactPrice" :
        return JsxRuntime.jsxs(Price.make, {
                    children: [
                      propertyPrice.price.formatted,
                      children
                    ],
                    size: size,
                    className: className
                  });
    case "PriceRange" :
        return JsxRuntime.jsxs(Price.make, {
                    children: [
                      propertyPrice.minPrice.formatted,
                      " - ",
                      propertyPrice.maxPrice.formatted,
                      children
                    ],
                    size: size,
                    className: className
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected price: " + propertyPrice._0);
    
  }
}

var PriceDisplay = {
  make: PropertyPrice$PriceInternal$PriceDisplay
};

function PropertyPrice$PriceInternal$Price(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var property = use$1(props.property);
  return JsxRuntime.jsx(PropertyPrice$PriceInternal$PriceDisplay, {
              propertyPrice: property.price.fragmentRefs,
              size: props.size,
              className: props.className,
              children: Caml_option.some(children)
            });
}

var Price$1 = {
  make: PropertyPrice$PriceInternal$Price
};

var PriceInternal = {
  PropertyPriceFragment: PropertyPriceFragment,
  PropertyPricePriceFragment: PropertyPricePriceFragment,
  PriceDisplay: PriceDisplay,
  Price: Price$1
};

function PropertyPrice$WithoutErrorBoundary(props) {
  var className = props.className;
  var size = props.size;
  var property = use(props.property);
  switch (property.__typename) {
    case "ForSalePropertyV5" :
        return JsxRuntime.jsx(PropertyPrice$PriceInternal$Price, {
                    property: property.fragmentRefs,
                    size: size,
                    className: className
                  });
    case "RentalPropertyV5" :
        return JsxRuntime.jsx(PropertyPrice$PriceInternal$Price, {
                    property: property.fragmentRefs,
                    size: size,
                    className: className,
                    children: "/month"
                  });
    case "__unselected" :
        return UnselectedUnionMemberError.raise("Unselected member: " + property._0);
    
  }
}

var WithoutErrorBoundary = {
  make: PropertyPrice$WithoutErrorBoundary
};

function PropertyPrice(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "price",
              children: JsxRuntime.jsx(PropertyPrice$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size,
                    className: props.className
                  })
            });
}

var Price$2;

var make = PropertyPrice;

exports.PropertyFragment = PropertyFragment;
exports.PriceInternal = PriceInternal;
exports.Price = Price$2;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* Price Not a pure module */
