// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NumberUtils = require("../NumberUtils.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PropertyAttributes = require("../../cap/Property/components/PropertyAttributes.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SingleFamilyPropertyAttributes_singleFamilyProperty_graphql = require("../../../__generated__/SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.bs.js");

var convertFragment = SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(SingleFamilyPropertyAttributes_singleFamilyProperty_graphql.node, convertFragment, fRef);
}

var PropertyTypeFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function SingleFamilyPropertyAttributes(props) {
  var __displayPropertyType = props.displayPropertyType;
  var displayPropertyType = __displayPropertyType !== undefined ? __displayPropertyType : true;
  var match = use(props.propertyType);
  var halfBaths = match.halfBaths;
  var beds = match.beds;
  var baths = match.baths;
  var attributes = Core__List.filterMap({
        hd: [
          String(beds),
          beds === 1 ? "Bed" : "Beds"
        ],
        tl: {
          hd: [
            NumberUtils.formatFloat(baths),
            (baths | 0) === 1 ? "Bath" : "Baths"
          ],
          tl: {
            hd: [
              Core__Option.map(halfBaths, (function (prim) {
                      return String(prim);
                    })),
              Core__Option.map(halfBaths, (function (hb) {
                      if (hb === 1) {
                        return "HalfBath";
                      } else {
                        return "HalfBaths";
                      }
                    }))
            ],
            tl: {
              hd: [
                Core__Option.map(match.lotSize, (function (prim) {
                        return String(prim);
                      })),
                "SqftLot"
              ],
              tl: {
                hd: [
                  Core__Option.map(match.livingArea, (function (prim) {
                          return String(prim);
                        })),
                  "Sqft"
                ],
                tl: {
                  hd: [
                    displayPropertyType ? match.displayName : undefined,
                    "Empty"
                  ],
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }, (function (param) {
          var snd = param[1];
          var fst = param[0];
          if (fst !== undefined && snd !== undefined) {
            return [
                    fst,
                    snd
                  ];
          }
          
        }));
  return JsxRuntime.jsx(PropertyAttributes.make, {
              propertyAttributes: attributes,
              size: props.size
            });
}

var formatFloat = NumberUtils.formatFloat;

var make = SingleFamilyPropertyAttributes;

exports.PropertyTypeFragment = PropertyTypeFragment;
exports.formatFloat = formatFloat;
exports.make = make;
/* react/jsx-runtime Not a pure module */
