// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "ForSalePropertyV5",
              "RentalPropertyV5"
            ]);
}

var fragmentConverter = {"__root":{"RentalPropertyV5":{"f":""},"ForSalePropertyV5":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "priceFormat",
        "variableName": "priceFormat"
      },
      {
        "kind": "Variable",
        "name": "priceRangeFormat",
        "variableName": "priceRangeFormat"
      }
    ],
    "kind": "FragmentSpread",
    "name": "PropertyPrice_price"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "FULL",
      "kind": "LocalArgument",
      "name": "priceFormat"
    },
    {
      "defaultValue": "FULL",
      "kind": "LocalArgument",
      "name": "priceRangeFormat"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyPrice_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ForSalePropertyV5",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "RentalPropertyV5",
      "abstractKey": null
    }
  ],
  "type": "PropertyV5",
  "abstractKey": "__isPropertyV5"
};
})());

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_fragment = unwrap_fragment;
exports.wrap_fragment = wrap_fragment;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
