// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var PropertyAttributes = require("../../cap/Property/components/PropertyAttributes.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var LotPropertyAttributes_lotProperty_graphql = require("../../../__generated__/LotPropertyAttributes_lotProperty_graphql.bs.js");

var convertFragment = LotPropertyAttributes_lotProperty_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, LotPropertyAttributes_lotProperty_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LotPropertyAttributes_lotProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LotPropertyAttributes_lotProperty_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(LotPropertyAttributes_lotProperty_graphql.node, convertFragment, fRef);
}

var PropertyTypeFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function LotPropertyAttributes(props) {
  var match = use(props.propertyType);
  var attributes = Belt_List.concatMany([
        props.displayPropertyType ? ({
              hd: [
                match.displayName,
                "Empty"
              ],
              tl: /* [] */0
            }) : /* [] */0,
        {
          hd: [
            String(match.size),
            "SqftLot"
          ],
          tl: /* [] */0
        }
      ]);
  return JsxRuntime.jsx(PropertyAttributes.make, {
              propertyAttributes: attributes,
              size: props.size
            });
}

var make = LotPropertyAttributes;

exports.PropertyTypeFragment = PropertyTypeFragment;
exports.make = make;
/* react/jsx-runtime Not a pure module */
